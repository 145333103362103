import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { SpeedInsights } from "@vercel/speed-insights/react";
import BirthdayPopup from "./components/BirthdayPopup";
// import VideoPopup from './components/VideoPopup';

import Birthdays from "./files/birthday.json";
import bakareImage from "./files/bakareimage.png";
import nodeJsIcon from "./files/icons/nodejs.png";
import gitIcon from "./files/icons/git.png";
import typescriptIcon from "./files/icons/ts.png";
import mongodbIcon from "./files/icons/mongodb.png";
import mysqlIcon from "./files/icons/mysql.png";
import microservicesIcon from "./files/icons/microservice.png";
import goIcon from "./files/icons/go.png";
import psqlIcon from "./files/icons/psql.png";
import redisIcon from "./files/icons/redis.png";
import react from "./files/icons/react.png";
import express from "./files/icons/express.png";
import nest from "./files/icons/nest.png";
import linuxIcon from "./files/icons/linux.png";
import uhunger from "./files/logos/uhunger.jpeg";
import buytrek from "./files/logos/buytrek.png";
import finpay from "./files/logos/finpay.png";
import vaultly from "./files/logos/vaultly.png";
import classoptima from "./files/co.jpeg";
import voting from "./files/logos/voting.png";
import mail from "./files/logos/mailer.jpeg";
import "./Style.css";
import Contact from "./components/Contact";

function App() {
	const projects = [
		{
			title: "Uhunger",
			description:
				"A food delivery application tailored specifically for campus environments, enabling students to order meals from on-campus restaurants directly to their dormitories. The platform ensures a seamless user experience with an intuitive interface and order tracking. Built using Node.js (Express), the backend handles order management, user authentication, and menu management. The project emphasizes scalability and user convenience.",
			link: "https://uhunger.com",
			image: uhunger,
		},
		{
			title: "ClassOptima",
			description:
				"A timetable scheduling solution designed for educational institutions to manage class schedules efficiently. ClassOptima simplifies the process of allocating time slots, preventing conflicts, and optimizing resource utilization. The backend, built with Node.js (Express), ensures high performance, and reliability making it adaptable to schools of various sizes.",
			link: "https://github.com/bakare-dev/ClassOptima-Api",
			image: classoptima,
		},
		{
			title: "BuyTrek",
			description:
				"An e-commerce platform that provides users with a secure and easy way to buy and sell products. The system supports core features like product listing, shopping carts, and payment gateway integration. Built with Node.js (Nest), BuyTrek emphasizes data security and scalability, ensuring a smooth experience for both sellers and buyers.",
			link: "https://github.com/bakare-dev/BuyTrek",
			image: buytrek,
		},
		{
			title: "Fin Pay",
			description:
				"A fintech API designed to facilitate financial operations like fund transfers, wallet integration, and transaction tracking. Built with Node.js (Express), the system ensures secure processing of financial data. Features include 2fa, transaction updates, and support for multiple currencies.",
			link: "https://github.com/bakare-dev/bakare-fin-pay",
			image: finpay,
		},
		{
			title: "Vaultly",
			description:
				"A banking API that supports essential banking functionalities, including user authentication, account management, and money transfers between accounts. Built with Go (Gin), the system prioritizes speed, scalability, and security. Vaultly implements robust error handling and offers modular code for easy feature extension.",
			link: "https://github.com/bakare-dev/simple-bank-api",
			image: vaultly,
		},
		{
			title: "Voting System",
			description:
				"A voting platform designed for conducting elections in schools, organizations, and communities. Built with Node.js (Express), the backend manages voter registration, election setup, and vote counting with integrity. Features role-based access control to ensure transparency and reliability during the voting process.",
			link: "https://github.com/bakare-dev/lusc_voting_api",
			image: voting,
		},
		{
			title: "Mailer",
			description:
				"An email-sending API capable of handling bulk and transactional emails efficiently. Built with Node.js (Express).",
			link: "https://github.com/bakare-dev/hills-mailer",
			image: mail,
		},
	];

	const skills = [
		{ name: "TypeScript", icon: typescriptIcon },
		{ name: "Node.js", icon: nodeJsIcon },
		{ name: "Express.js", icon: express },
		{ name: "Nest.js", icon: nest },
		{ name: "Go", icon: goIcon },
		{ name: "React", icon: react },
		{ name: "MySQL", icon: mysqlIcon },
		{ name: "PostgreSQL", icon: psqlIcon },
		{ name: "MongoDB", icon: mongodbIcon },
		{ name: "Redis", icon: redisIcon },
		{ name: "Git", icon: gitIcon },
		{ name: "Microservices", icon: microservicesIcon },
		{ name: "Linux", icon: linuxIcon },
	];

	const [showPopup, setShowPopup] = useState(false);
	const [popupMessage, setPopupMessage] = useState("");

	useEffect(() => {
		const makeApiCall = async () => {
			try {
				await fetch("https://bakare-mailer.onrender.com/api/v1/health");
			} catch (error) {
				console.error("API call failed", error);
			}
		};

		const checkBirthday = async () => {
			const data = Birthdays;
			const today = new Date();
			const todayDate = `${today.getDate()}th ${today.toLocaleString(
				"default",
				{ month: "long" }
			)}`;

			const birthday = data.find((item) => item.date === todayDate);

			if (birthday) {
				setPopupMessage(birthday.message);
				setShowPopup(true);
			}
		};

		makeApiCall();
		checkBirthday();
	}, []);

	const handlers = useSwipeable({
		onSwipedLeft: () =>
			document
				.querySelector(".project-list")
				.scrollBy({ left: 200, behavior: "smooth" }),
		onSwipedRight: () =>
			document
				.querySelector(".project-list")
				.scrollBy({ left: -200, behavior: "smooth" }),
		trackMouse: true,
	});

	const currentYear = new Date().getFullYear();

	return (
		<div className="App">
			<header className="header">
				<nav className="nav-left">
					<a href="#projects">Projects</a>
					<a href="#contact">Contact Me</a>
				</nav>
				<div className="nav-center">
					<a href="#projects">Projects</a>
					<a href="#contact">Contact Me</a>
				</div>
				<div className="nav-right">
					<a
						href="https://github.com/bakare-dev"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaGithub />
					</a>
					<a
						href="https://www.linkedin.com/in/praise-bakare-5648971b0/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaLinkedin />
					</a>
					<a
						href="https://twitter.com/bakare_dev"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaTwitter />
					</a>
				</div>
			</header>

			<section className="hero">
				<span className="emoji emoji-1">💻</span>
				<span className="emoji emoji-3">🔍</span>
				<span className="emoji emoji-7">🧑‍💻</span>
				<span className="emoji emoji-8">🔒</span>
				<span className="emoji emoji-2">🚀</span>
				<span className="emoji emoji-11">🎯</span>
				<h1>
					Bakare Praise
					<img src={bakareImage} alt="cool" className="emoji-image" />
				</h1>
				<p className="role">Backend Engineer</p>
				<p className="resume-text">My Resume</p>
				<a
					href="/BAKARE PRAISE RESUME.pdf"
					download
					className="resume-button"
				>
					Download
				</a>
			</section>

			<section id="about" className="about">
				<div className="about-wrapper">
					<img
						src={bakareImage}
						alt="Bakare Praise"
						className="about-image"
					/>
					<div className="about-text-box">
						<div className="about-details">
							<h3>
								Bakare Praise Stephen{" "}
								<span role="img" aria-label="hello">
									👋
								</span>
							</h3>
							<p>
								Hi there! Let's build the next big thing and
								make some tech magic happen!
							</p>
							<p>
								I’ve had the opportunity to work on exciting
								projects alongside talented engineers to bring
								innovative ideas to life. Let’s join forces and
								build something amazing together!
							</p>   
						</div>
					</div>
				</div>
			</section>

			<section id="skills" className="skills">
				<h2>Skills</h2>
				<div className="skills-list">
					{skills.map((skill) => (
						<div key={skill.name} className="skill-item">
							<img
								src={skill.icon}
								alt={skill.name}
								className="skill-icon"
							/>
							<span>{skill.name}</span>
						</div>
					))}
				</div>
			</section>

			<section
				id="projects"
				className="projects"
				{...(window.innerWidth < 768 ? handlers : {})}
			>
				<h2 id="project-title">Projects</h2>
				<div className="project-list">
					{projects.map((project, index) => (
						<a
							key={index}
							href={project.link}
							target="_blank"
							rel="noopener noreferrer"
							className="project-item"
						>
							<div className="project-content">
								<h3 className="project-title">
									{project.title}
								</h3>
								<img
									className="project-image"
									src={project.image}
									alt={project.title}
								/>
								<p className="project-description">
									{project.description}
								</p>
							</div>
							<div className="project-overlay">
								<div className="outer-circle">
									<div className="inner-circle">
										<i className="arrow-icon">→</i>
									</div>
								</div>
							</div>
						</a>
					))}
				</div>
			</section>

			<Contact />

			<footer className="footer">
				<div className="footer-left">
					Bakare Praise | Copyright &copy; {currentYear}
				</div>
				<div className="footer-right">Thank you for visiting</div>
				<div className="footer-center">
					<p>Bakare Praise | Copyright &copy; {currentYear}</p>
					<p>Thank you for visiting</p>
				</div>
			</footer>
			<SpeedInsights />
			{showPopup && (
				<BirthdayPopup
					message={popupMessage}
					onClose={() => setShowPopup(false)}
				/>
			)}
		</div>
	);
}

export default App;
